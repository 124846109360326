.pipelineItemHeader {
  display: flex;
  flex-direction: row;
  margin-inline-end: auto;
}
.pipelineItemHeader span {
  font-size: 18px;
  font-weight: 500;
}
.pipeline-logo-div {
  width: 15%;
  margin-left: 4%;
}
.piplineLogo {
  margin-left: 1rem;
  float: left;
  width: 0.3rem;
  height: 0.3rem;
  padding: 3px;
  color: seashell;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 66px;
  background: #fb6c51;
  border: 14px solid #fb6c51;
}
.pipelineContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 2% !important;
  overflow-x: scroll;
  width: 100%;
}
/* .pipelineContentItem{
  padding: 5px;
  text-align: center;
  width: 78px;
  color: #FB6C51;
  font-size: small;
  border-radius: 50px ;
  box-shadow: 0px 0px 50px #2A59980D;
  background-color: rgb(252, 222, 222);
  opacity: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  
} */
/* .pipelineContentItem:hover {
  overflow:visible;
} */

.getIcon {
  width: 40px;
}

.pipelineName {
  padding-left: 5px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat';
}

.Companies {
  margin-right: 10px;
  margin-left: 13px;
}
.Companies_image {
  align-items: center;
  transform: scale(1.1);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0.2rem;
  max-height: auto;
  box-shadow: 13px -6px 43px #00000029;
}

.ItemHeader {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 3%;
  justify-content: space-evenly;
}

.card-part-1 {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  height: 70%;
  margin-right: 1%;
}

.main-card-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card-part-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 22%;
}
.card-part-3 {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  height: 0%;
}

.metrics-value {
  color: black;
  text-align: center;
  overflow-y: hidden;
  font-family: 'Montserrat' !important;
}

.metrics-name {
  color: grey;
  text-align: center;
  font-size: 0.9rem;
  font-family: 'Poppins' !important;
}
