.pipelineItemsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  align-items: center;
}
.pipelineParentContainer {
  height: 90%;
  margin: 1.5%;
}
.heading {
  font-weight: bold;
  font-size: 20px;
  margin-left: 24px;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.pulse-spinner {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fb6c51;
}
.pulse-spinner::after {
  content: '';
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fb6c51;
  z-index: 1;
  position: absolute;
  animation: pulse 1s ease infinite;
}
@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
.square {
  width: 100px;
  height: 100px;
  position: relative;
  perspective: 200px;
}

.square div {
  position: absolute;
  top: 0;
  height: 50px;
  width: 50px;
  background: #f75054;
  animation: flip 1.4s linear infinite;
  transform-origin: right bottom;
}

.square div:nth-child(2) {
  animation-delay: 0.7s;
  opacity: 0.5;
}

@keyframes flip {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  25% {
    transform: rotateX(0) rotateY(180deg);
  }
  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: rotateX(180deg) rotateY(0);
  }
  100% {
    transform: rotateX(0) rotateY(0);
  }
}
