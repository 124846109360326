.sidebarLogoContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
}

.headerImage {
  top: 78px;
  left: 120px;
  height: auto;
  background: transparent;
  width: 70%;
  object-fit: contain;
}

.title1 {
  top: 79px;
  left: 176px;
  width: 123px;
  height: 37px;
  text-align: left;
  font: normal normal bold 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}

.title2 {
  top: 114px;
  left: 176px;
  width: 67px;
  height: 19px;
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #f75054;
  opacity: 1;
}
