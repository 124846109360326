.card {
  width: 17rem;
  height: 17rem;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0.8rem;
}

@media screen and (max-width: 1500px) and (min-width: 1350px) {
  .card {
    width: 29%;
    height: 19rem;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1501px) {
  .card {
    width: 22rem;
    height: 19rem !important;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1601px) {
  .card {
    width: 17.6rem;
    height: 18rem !important;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .card {
    width: 19.3rem;
    height: 19rem !important;
  }
}

@media screen and (max-width: 1900px) and (min-width: 1801px) {
  .card {
    width: 20.8rem;
    height: 19rem !important;
  }
}
