.infraDetailsContainer {
  height: 100%;
  background-color: #e7edf5;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  align-content: flex-end;
  overflow-y: scroll;
}

.infraDescrption {
  background-color: white;
  height: 95.3%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1%;
}

.infraDescrptionDetails {
  height: 70%;
  overflow: auto;
  text-align: left;
  font-family: Montserrat;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -10px;
  font-size: 0.9rem;
  color: #7f7f7f;
}
p.infraDescrptionDetails::-webkit-scrollbar {
  width: 3px !important;
}
.infraDescrptionButtonColumn {
  display: flex;
  flex-direction: column;
  height: 25%;
  width: 80%;
  justify-content: space-evenly;
  margin-bottom: 15%;
}

.triangle {
  display: none;
}

.Companies_image:hover {
  transform: scale(1.1);
}
.Companies {
  margin-right: 12px;
  margin-left: 15px;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 3vh solid white;
  margin-left: 2.5vh;
  margin-top: 1vh;
}

.testimonials {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  font-family: Montserrat;
  margin-left: 1%;
  width: 98%;
}
.testimonials p {
  font-weight: 400;
  font-size: 2.3vh;
  max-width: 800px;
}
@media only screen and (max-width: 1800px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 700px;
  }
}
@media only screen and (max-width: 1700px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 600px;
  }
}
@media only screen and (max-width: 1500px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 500px;
  }
}
@media only screen and (max-width: 1400px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 500px;
  }
}
@media only screen and (max-width: 1350px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 440px;
  }
  .infraDescrption {
    height: 110%;
  }
}
@media only screen and (max-width: 1300px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 440px;
  }
  .infraDescrption {
    height: 110%;
  }
}
@media only screen and (max-width: 1200px) {
  .testimonials p {
    font-weight: 400;
    font-size: 2.3vh;
    max-width: 320px;
  }
}
.MainInfraDescrption {
  height: 100%;
  width: 22%;
  margin: 1%;
  margin-top: 2.6rem;
  margin-bottom: 6%;
}

.infraDetailsDiv {
  width: 78%;
  margin: 1%;
  height: 91.5%;
}
.infraImg {
  width: 98%;
  height: 55%;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin: 0.75%;
  margin-bottom: 1.5%;
}
.infraName {
  color: #3b3b3b;
  font-family: Poppins;
  font-size: 1.3em;
  font-weight: 600;
  margin-left: 1%;
}
.CompaniesMainDiv {
  display: flex;
  margin-top: 20px;
  margin-left: 1%;
}
.DescriptionHeading {
  justify-content: center;
  font-family: Montserrat;
  font-size: 1.4em;
}
.descriptionFormM {
  width: -webkit-fill-available;
  margin-bottom: 20%;
  display: grid;
}
.downloadBtn {
  width: -webkit-fill-available;
}
.companiesHeading {
  font-family: Montserrat;
  font-size: 2.6vh;
  margin-top: 4vh;
  margin-left: 1%;
}
.DivBack {
  padding-left: 0.625rem;
}
.backButton {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #7f7f7f;
}

.cloud_icon {
  margin-top: 10px;
  margin-left: 18px;
}
