.navInput {
  padding: 0.3rem;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid #a8afb4;
  width: 100%;
  padding-left: 43px;
  height: -webkit-fill-available;
  outline: transparent;
}
.inputSearch {
  width: 25rem;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.NavbarModel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 65%;
}
.MuiButton-label {
  width: 50%;
  display: flex;
  text-transform: none;
  justify-content: flex-end;
  float: right;
  font-family: 'Montserrat';
}

.PipelineSearchBar {
  display: flex;
  flex-direction: row;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}
.LogedUserDiv {
  display: flex;
  align-items: center;
  padding-right: 1.2%;
}
.Loginuser {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ProfilePicMain {
  display: flex;
  align-items: center;
}
.ProfilePicSub {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #fb6c51;
  text-align: center;
  font-size: 32px;
  font-weight: bolder;
  color: white;
}
.firstName {
  padding-bottom: 5px;
  font-weight: 600;
  color: #fb6c51;
  font-family: normal normal 600 20px/30px Poppins;
}
.userName {
  margin: 0;
  font-size: 12px;
  color: #9b9b9b;
  font-family: normal normal normal 16px/25px Poppins;
}
