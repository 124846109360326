.sidebarItemsContainer {
  margin-top: 12px;
  flex: content;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.itemDiv {
  display: flex;
  align-items: center;
}
.btn {
  display: inline-block;
  background: transparent;
  border: 0.125em solid transparent;
  width: 170px;
  height: 17px;

  padding: 10px;
  position: relative;
  font-weight: bold;
  color: #878787;
  font: 'Poppins, sans-serif;';
  text-decoration: none;
  border-radius: 10px 0px 10px 10px;
  justify-content: center;
  margin: 10px 0px 0px 0px;
  white-space: nowrap;
  font-size: 13px;
  margin-right: 3em;

  box-sizing: unset !important;
}

.btn:after {
  content: '';
  position: absolute;
  display: block;
  background: transparent;
  padding: 10px;
  border: 0.125em solid transparent;
  width: 0.125em;
  border-radius: 0px 10px 10px 0px;
  height: 16px;
  top: -0.05em;
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  transform-origin: bottom left;
  right: -0.25em;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  transform: skewX(-20deg);
  border-left: none;
}

.btn.active {
  background: #f75054 0% 0% no-repeat padding-box;
  border: 0.125em solid transparent;
  width: 170px;
  height: 26px;
  padding: 10px;
  position: relative;
  font-weight: bold;
  color: #fff;
  font: 'Poppins, sans-serif;';
  text-decoration: none;
  border-radius: 10px 0px 12px 10px;
  justify-content: center;
  margin: 10px 0px 0px 0px;
  white-space: nowrap;
  font-size: 13px;
  margin-right: 3em;
}

.btn.active:after {
  content: '';
  position: absolute;
  display: block;
  background: #f75054 0% 0% no-repeat padding-box;
  padding: 10px;

  width: 0.125em;
  border-radius: 0px 10px 10px 0px;
  height: 25px;
  top: -0.05em;
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  transform-origin: bottom left;
  right: 0.03em;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  transform: skewX(-26deg);
  border-left: none;
}
.sidebarItemsContainer {
  padding: 10px;
}

.item-sub-div {
  width: 20px;
  margin-right: 10px;
  position: relative;
  left: -9px;
}
