.sidebar-main-div {
  height: 92.5%;
  min-width: 250px;
  padding: 15px;
  padding-right: 7.5px;
}

.sidebar-sub-div {
  width: 100%;
  height: 100%;
  background-color: #e7edf5;
  display: inline-block;
  border-radius: 10px;
}
.logout-div {
  position: absolute;
  bottom: 7%;
  margin-left: 2%;
}
