/* @use postcss-color-function;
@use postcss-nested; */
.App {
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.search-class {
  width: 300px;
  border: 1px solid grey;
  border-radius: 20px;
  height: 5px;
}
.Nav-style {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.Nav-style input {
  margin-top: 10px;
  margin-left: 30px;
  width: 300px;
  height: 25px;
  font-size: 18px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 25px;
}

html,
body,
#root {
  height: 100% !important;
  width: 100%;
  font-family: 'Montserrat';
}

.App {
  width: inherit;
  height: 100%;
}

.rutuj3 {
  padding: 0;
  height: 250px !important;
}

.imgresize {
  height: 100%;
  max-width: 96%;
  object-fit: contain;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgb(221, 223, 226);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #969696;
}

.container {
  width: 70%;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  flex-grow: 1;
  position: relative;
}

.appContainer {
  width: 100%;
  height: 87.5%;
  margin-top: 0.6%;
  background-color: #e7edf5;
  flex-grow: 1;
  display: inline-block;
  border-radius: 10px;
  flex-wrap: wrap;
  overflow: auto;
}

.ProgressLoader {
  text-align: center;
  margin-top: 20%;
}
